import React from 'react';
import MetaTags from 'react-meta-tags';
import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Joensuu_NakoPiste_optikko_Marjo_Jormanainen_Irma_Rosch.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/Optikot.jpg',
    originalAlt: 'Joensuun NäköPisteen optikot Marjo Jormanainen ja Irma Rösch'
  },
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Optikko_Joensuu_NakoPiste_Siltakatu-12.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/Liike_Ulkoa.jpg',
    originalAlt: 'Joensuun keskustassa sijaitseva optikkoliike Joensuun NäköPiste'
  },
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Optikko_Joensuu_NakoPiste_kehykset.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/Liike_1.jpg',
    originalAlt: 'Joensuun NäköPisteen kattava miesten kehysvalikoima'
  },
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Joensuu_NakoPiste_mittaustietokone.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/Liike_3.jpg',
    originalAlt: 'Joensuun NäköPisteen mittaustietokone mahdollistaa juuri sinulle sopivat silmälasit'
  },
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Joensuu_NakoPiste_sisätilat.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/Liike_2.jpg',
    originalAlt: 'Joensuun NäköPisteen kattava naisten kehysvalikoima'
  },
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Optikko_Joensuu_NakoPiste_näöntarkastus.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/NT_1.jpg',
    originalAlt: 'Hyvä ja asiantunteva palvelu Joensuun NäköPisteestä'
  },
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Optikko_Joensuu_NakoPiste_näöntarkastus_2.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/NT_2.jpg',
    originalAlt: 'Joensuun NäköPisteen mukava optikon näöntarkastushuone'
  },
  {
    original: 'https://nakopiste.fi/Resources/palvelumme/Joensuun_NakoPiste_Optikko_Marjo_Jormanainen.jpg',
    thumbnail: 'https://nakopiste.fi/Resources/palvelumme/preview/NT_Marjo_Jormanainen.jpg',
    originalAlt: 'Joensuun NäköPisteen optikko Marjo Jormanainen'
  }
]

class TuotteetPalvelut extends React.Component {
  componentDidMount() {
    document.title = "Joensuun Näköpisteen Palvelut ja Tuotteet"
  }
  
  render(){
        return(
            <div className="palvelumme_page">
              <MetaTags>
                  <meta name="description" content="Joensuun NäköPiste tarjoaa laadukkaita optikkopalveluita sekä tuotteita kuten simälasit, aurinkolasit ja työlasit" />
              </MetaTags>
    <div className="palvelumme_content">  
      <div className="text">
        <h1>Palvelumme</h1>
          <p>Optikkomme Marjo Jormanainen ja Irma Rösch ovat ammattilaisia jo yli 35 vuoden kokemuksella optisesta alasta. Meiltä saat kattavasti näkemisen hyvinvointiin liittyvät palvelut:optikon näöntarkastukset , työnäkötarkastukset, silmänpaineen mittaukset, piilolasisovitukset ja kuivasilmätutkimukset. Tarvittaessa ohjaamme sinut silmälääkäriin tarkempiin tutkimuksiin.</p>
          <p>Varaa aika näöntarkastukseen suoraan liikkeestä <b>p. 050 3585 855</b> tai <a href="http://vello.fi/joensuun-nakopiste"target="_blank" rel="noopener noreferrer">Nettiajanvaraus palvelustamme</a>, voit myös varata ajan sähköpostitse <a href="mailto:optikko@nakopiste.fi">optikko@nakopiste.fi</a>.</p>
          <p>Joensuun NäköPisteessä noudatamme alan hyvää näöntutkimuskäytäntöä ja eettisiä ohjeita. Tarjoamme parasta palvelua vuosikymmenten kokemuksella ja toimivat, kunnolliset silmälasit nopealla toimituksella.</p>
        <h2>Silmälasit</h2>
          <p>Silmälasit tehdään yksilöllisesti sinulle ja sinun käyttötarpeitasi ajatellen. Tärkeintä kaikissa silmälaseissa on, että ne istuvat hyvin ja ovat mukavat, sekä helpot käyttää ja toimivat siinä tilanteessa mihin ne on hankittu - saatat tarvita jopa useammat lasit eri käyttötarkoituksiin kuten moniteholasit ja lukulasit!</p>
          <p>Mitoitamme silmälasit tarkasti käsimittauksilla ja iPadillä mitoitusohjelmiston avulla, näin saamme räätälöityä juuri sinulle sopivat silmälasit - tottumistakuulla tietenkin! Silmälasien toimitusaika on meillä noin 1-2 viikkoa. Optikon näöntarkastukseen pääsee usein myös saman tien, ilman ajanvarausta - tule sisään liikkeeseen ja kysy!</p>
          <p>Kehysvalikoimamme on laaja ja valittu juuri asiakkaidemme toiveiden mukaan. Meiltä löytyy hyvä titan-kehysmallisto ja paljon trendikkäitä ja värikkäitä muovikehyksiä. joihin tarvittaessa saa korotusnenätyynyt tai varrelliset nenätallat kehyksen istuvuutta parantamaan. Kehysvalikoimastamme löytyy sekä pieniä kapeita kehyksiä että leveitä kehyksiä. Meiltä saat sopivan kokoiset silmälasit miesten, naisten ja lasten kasvoille!</p>
          <p>Kehysmerkkejämme: Pilgrim, Skaga, Longchamp, Tommy Hilfiger, Guess, HK-Titanium, Swarovski, Imago, Pix, Woodys, Safilo ja monia muita.</p>
        <h2>Linssit</h2>
          <p>Silmälasien linssien valinnassa otamme huomioon valitun kehyksen ja sen asettamat vaatimukset. Selvitämme käyttötoiveesi ja lasiesi käyttötarpeen, ja otamme huomioon aikaisempien linssien käyttökokemukset. Tarvitset sitten lukulasit tai monitehot, niin oikealla linssivalinnalla varmistamme lasiesi parhaan toimivuuden ja näkemisen helppouden eri tilanteissa. Linssit valmistetaan nykyisin useimmiten muovista ja niihin voidaan laittaa erilaisia lisäominaisuuksia kuten kova- ja  heijastamaton pinta ja sinisen valon suodatus.</p>
          <p>Silmälaseissa käytämme pääosin Hoyan laatulinssejä. Valikoimastamme löytyvät myös Essilorin, Zeissin ja Piilosetin linssit, jotka asennamme aina yksilöllisesti mitoittaen.</p>
        <h2>Aurinkolasit</h2>
          <p>On myös tärkeää suojata silmät voimakkaalta auringon valolta ja uv-säteilyltä. Muistathan suojata myös lasten silmät! Hyvät aurinkolasit eivät vääristä kuvaa ja tarvittaessa saat myös aurinkolasit omilla vahvuuksilla.</p>
        <h2>Piilolinssit ja korjaukset</h2>
          <p>Meiltä saat myös piilolinssinesteet ja tilauksesta piilolinssit. Teemme myös silmälasien huoltoa, säätöjä ja korjauksia liikkeessämme. Korjaukset tehdään usein odottaessa</p>
        <br></br>
        <h3>NäköPisteessä nähdään! </h3>
      </div>
      <div className="gallery_container">
         <ImageGallery items={images} additionalClass="img_galleria" />
      </div>
    </div>
  </div>
        );
    }
}

export default TuotteetPalvelut