import React from 'react';
//import FBpage from 'facebook-plugins';

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div id="privacy-policy" className="text_content"> 
                <p>
                    

<b>1. Rekisterinpitäjä</b><br></br>
 Fenno Optiikka: Joensuun Näköpiste<br></br>
 Postiosoite: Siltakatu 12C<br></br>
 Postinumero ja postitoimipaikka: 80100 Joensuu<br></br>
 Y-tunnus: 2561423-6<br></br>
 <br></br>
 <b>2. YHTEYSHENKILÖ REKISTERIÄ KOSKEVISSA ASIOISSA</b><br></br>
 Yrittäjän etunimi ja sukunimi: Marjo Jormanainen<br></br>
 Postiosoite: Siltakatu 12C<br></br>
 Postinumero ja postitoimipaikka: 80100 Joensuu<br></br>
 <br></br>
 <b>3. REKISTERIN NIMI</b><br></br>
 Fenno Optiikka: Joensuun NäköPiste Oy:n asiakasrekisteri<br></br><br></br>
<b>4. REKISTERIN KÄYTTÖTARKOITUS</b><br></br>
Asiakasrekisteriä käytetään optikkoliikkeessä potilaan tutkimuksen ja hoidon suunnitteluun,
rekisterin pitäjän oman liiketoiminnan suunnitteluun ja toteutukseen, asiakassuhteen hoitoon
sekä optistentietojen asetukseen perustuvan hoitotietojen säilytysvelvoitteen toteuttamiseen
(SMT:n asetus potilasasiakirjojen laatimisesta sekä niiden ja muun hoitoon liittyvän materiaalin
säilyttämisestä 99/2001) Rekisteriä käytetään asiakasviestintään ja markkinointiin. <br></br><br></br>
<b>5. REKISTERIN TIETOSISÄLTÖ</b><br></br>
Rekisteri sisältää seuraavia perustietoja:
<ul>
    <li>Hetu</li>
    <li>Etu- ja sukunimet</li>
    <li>Yhteystiedot (osoitteet, puhelinnumerot, email-osoite)</li>
    <li>Sukupuoli</li>
    <li>Optiset terveystiedot</li>
    <li>Suostumus suoramarkkinointiin ja näöntarkastuskutsujen lähettämiseen</li>
    <li>Suostumukset ja kiellot terveystietojen käyttämiseen</li>
    <li>Asiakassuhteen hoitoon ja ylläpitoon liittyvät tiedot</li>
</ul>
<b>6. SÄÄNNÖNMUKAISET TIETOLÄHTEET</b><br></br>
Asiakkaan henkilökohtaisen käynnin yhteydessä henkilökunnalle antamat tiedot tai
sähköisissä palveluissa itse päivittämänsä tiedot. Alaikäisen osalta huoltajan yhteystiedot.<br></br><br></br>

Päivitystiedot: yksityisten ja julkisten tietopalveluiden tarjoajien luovuttamat muutostiedot
asiakasrekisteripäivityksen yhteydessä.<br></br><br></br>
<b>7. Säännönmukaiset tietojen luovutukset ja tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle</b>
<ul><li><b>Ei luovuteta</b></li></ul><br></br>

<b>8. REKISTERIN SUOJAUKSEN PERIAATTEET</b><br></br>
Asiakasrekisteri on ainoastaan Fenno Optiikka Joensuun NäköPiste Oy:n henkilökuntaan kuuluvien henkilöiden käytössä, jotka tarvitsevat tietoja tehtävissään. Rekisterissä olevan tiedon muokkaaminen
edellyttää henkilökohtaista käyttäjätunnusta ja salasanaa. Käyttäjätunnus ja salasana annetaan käyttöoikeuden myöntämisen yhteydessä. Käyttöoikeus päättyy, mikäli henkilö ei ole enää yhtiössä töissä.
<br></br>
Rekisterin varmennus tapahtuu automaattisesti joka päivä ulkopuolisen palveluntuottajan toimesta
kryptattuna tietona.<br></br>
Fenno Optiikka Joensuun NäköPiste Oy:n tietokoneet on suojattu palomuurein ja automaattisesti päivittyvällä virustorjuntaohjelmalla. Liike käyttää palomuurein suojattua yritysverkkoa. 
<br></br><br></br>
<b>9. TARKASTUSOIKEUS</b><br></br>
Rekisteröidyn erikseen niin kirjallisesti pyytäessä toimitetaan häntä koskevat henkilötiedot järjestelmästä.
Tiedot toimitetaan selkokielisessä formaatissa. <br></br><br></br>
<b>10. OIKEUS VAATIA TIEDON KORJAAMISTA</b><br></br>
Mikäli rekisteröity pyytää oikaisemaan virheellisiä tai puutteellisia henkilötietoja, tehdään muutokset sen
perusteella. Oikaisupyynnöt tulee osoittaa kirjallisesti ja pyynnöt kohdistetaan lähettäjän sähköpostiosoitteeseen liitettyihin henkilötietoihin, ellei pyynnön lähettänyttä henkilöä tunnisteta jollain muulla tavalla. 
<br></br><br></br>
<b>11. MUUT HENKILÖTIETOJEN KÄSITTELYYN LIITTYVÄT OIKEUDET</b><br></br>
<ul>
    <li>Oikeus tulla unohdetuksi toteutetaan merkitsemällä tiedot siten, ettei niitä enää käsitellä järjestelmässä. Tietoja ei hävitetä, vaan piilotetaan käyttäjältä.</li>
    <li>Henkilötiedot voidaan siirtää toiseen järjestelmään.</li>
    <li>Rekisteröidyllä käyttäjällä on oikeus tehdä valitus valvontaviranomaiselle, jos henkilötietojen käsittelyssä rikotaan asetusta.</li>
</ul>
                </p>
            </div>
        )
    }
}

export default Privacy