import React from 'react';
import './header.scss';

class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="header">
                <img id="logo" src='https://nakopiste.fi/Resources/logot/nakopiste_logo.png' alt="Näköpiste"></img>
            </div>
        )
    }
}

export default Banner