import React from 'react';
import { useEffect } from "react";
import { Switch, Route } from 'react-router-dom';
import './App.css';

import Banner from './components/header.js';
import Navbar from './components/nav.js';
import Footer from './components/footer.js';
import Evasteet from './components/evasteet.js';
import Privacy from './components/privacy.js';
import EvasteetMuok from './components/evasteetM.js'

//import pages
import Etusivu from './pages/etusivu.js';
import TuotteetPalvelut from './pages/tuotteetpalvelut';
import Huomiot from './pages/huomiot';

import './components/maincontent.scss';
import './main.scss';
import CookieConsent, {
  getCookieConsentValue,
} from "react-cookie-consent"
import Cookies from 'js-cookie';

function App() {
  var ConsentValues = {};
  const handleAcceptCookie = (ConsentValue) => {
    //console.log(typeof ConsentValue);
    if(typeof ConsentValue === "object") {
      if(ConsentValue.analytics === true) {
        //23.6 Muutos, poistetaan Google Analytics
        // Remove the default tracker.
        if (window.ga) window.ga('remove');
        // Remove the default cookies
        // _ga is used to distinguish users.
        Cookies.remove('_ga', { path: '/', domain: document.domain });
        // _gid is used to distinguish users.
        Cookies.remove('_gid', { path: '/', domain: document.domain });
        // _gat is used to throttle request rate.
        Cookies.remove('_gat', { path: '/', domain: document.domain });
        // _gat_gtag_UA_193981436_1
        Cookies.remove('_gat_gtag_UA_193981436_1', { path: '/', domain: document.domain });
      }
      else if(ConsentValue.analytics === false) {
        // Remove the default tracker.
        if (window.ga) window.ga('remove');
        // Remove the default cookies
        // _ga is used to distinguish users.
        Cookies.remove('_ga', { path: '/', domain: document.domain });
        // _gid is used to distinguish users.
        Cookies.remove('_gid', { path: '/', domain: document.domain });
        // _gat is used to throttle request rate.
        Cookies.remove('_gat', { path: '/', domain: document.domain });
        // _gat_gtag_UA_193981436_1
        Cookies.remove('_gat_gtag_UA_193981436_1', { path: '/', domain: document.domain });
      }
    }
    else if(ConsentValue==="true") {
      if (window.ga) window.ga('remove');
      // Remove the default cookies
      // _ga is used to distinguish users.
      Cookies.remove('_ga', { path: '/', domain: document.domain });
      // _gid is used to distinguish users.
      Cookies.remove('_gid', { path: '/', domain: document.domain });
      // _gat is used to throttle request rate.
      Cookies.remove('_gat', { path: '/', domain: document.domain });
      // _gat_gtag_UA_193981436_1
      Cookies.remove('_gat_gtag_UA_193981436_1', { path: '/', domain: document.domain });
    }
  };

  const handleDeclineCookie = () => {
    window.location = "/evasteet/muokkaus";
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie("true");
    }
    else if (isConsent !== "false" && isConsent !== "true" && isConsent !== undefined) {
      var newConsent = JSON.parse(isConsent);
      if (newConsent.marketing === true) {
        handleAcceptCookie(newConsent);
      }
    }
  }, []);

  var defaultCookie = { analytics:true, marketing:true, functionality:true };

  return (
    <div className="Site">
      <CookieConsent enableDeclineButton buttonText="Hyväksy kaikki" declineButtonText="Muokkaa valintoja" declineCookieValue={JSON.stringify(defaultCookie)} onAccept={handleAcceptCookie("true")} onDecline={handleDeclineCookie} buttonStyle={{ background:'#5bf033', fontSize: "13px" }}>
        Tämä verkkosivu käyttää Evästeitä parantamaan käyttökokemusta. Voit selata sivustomme <a href="/evastekaytanto">Evästekäytäntöä</a> tai <a href="/yksityisyyskaytanto">Yksityisyyskäytäntöä</a>
      </CookieConsent>
      <Banner/>
      <Navbar Cookies={ConsentValues}/>
      <Main/>
      <Footer/>
    </div>
  );
}

const EvasteetSivu = () => (
  <div className="evasteet_sivu">
    <Evasteet></Evasteet>
  </div>
)

const EvasteetMuokkaus = () => (
  <div className="evasteet_sivu">
    <EvasteetMuok MarketingProp={true} AnalyticsProp={true}></EvasteetMuok>
  </div>
)

const Main = () => (
  <Switch>
    <Route exact path='/' component={Etusivu}></Route>
    <Route exact path='/tuotteet' component={TuotteetPalvelut}></Route>
    <Route exact path='/ajankohtaista' component={Huomiot}></Route>
    <Route exact path='/evastekaytanto' component={EvasteetSivu}></Route>
    <Route exact path='/evasteet/muokkaus' component={EvasteetMuokkaus}></Route>
    <Route exact path='/yksityisyyskaytanto' component={Privacy}></Route>
  </Switch>
);

export default App;
