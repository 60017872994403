import React from 'react';
import { NavLink } from 'react-router-dom';
import CookieConsent, {
    getCookieConsentValue,
  } from "react-cookie-consent"
//import { NavLink, Switch, Route } from 'react-router-dom';
import './nav.scss';
import Cookies from 'js-cookie';

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CookiesConsent: this.props.Cookies
        }
    }

    componentDidMount() {
        var Consents = getCookieConsentValue();
        var FirstChar = ""
        if(Consents !== undefined) { 
            FirstChar = Consents.charAt(0)
        }
        else {
            FirstChar = "";
        }

        //Google Analytics cookie removal
        if (window.ga) window.ga('remove');
        // Remove the default cookies
        // _ga is used to distinguish users.
        Cookies.remove('_ga', { path: '/', domain: document.domain });
        // _gid is used to distinguish users.
        Cookies.remove('_gid', { path: '/', domain: document.domain });
        // _gat is used to throttle request rate.
        Cookies.remove('_gat', { path: '/', domain: document.domain });
        // _gat_gtag_UA_193981436_1
        Cookies.remove('_gat_gtag_UA_193981436_1', { path: '/', domain: document.domain });
    }

    render() {
        return (
            <div className="navbar" id="main-nav">
                <div className="leftnav">
                    <div className="navbutton" id ="etusivu"><button><NavLink to="/">Etusivu</NavLink></button></div>
                    <div className="navbutton" id="ajankohtaista"><button><NavLink to="/ajankohtaista">Ajankohtaista</NavLink></button></div>
                    <div className="navbutton" id="palvelut"><button><NavLink to="/tuotteet">Palvelumme</NavLink></button></div>
                </div>
            </div>
        )
    }
}

export default Navbar