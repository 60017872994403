import React from 'react';
import {getCookieConsentValue} from "react-cookie-consent";
import Cookies from 'js-cookie';

class EvasteetMuok extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analytics : props.AnalyticsProp,
            marketing : props.MarketingProp,
            functionality : true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    disableDefaultTracker = () => {
        // Remove the default tracker.
        if (window.ga) window.ga('remove');
        // Remove the default cookies
        // _ga is used to distinguish users.
        Cookies.remove('_ga', { path: '/', domain: document.domain });
        // _gid is used to distinguish users.
        Cookies.remove('_gid', { path: '/', domain: document.domain });
        // _gat is used to throttle request rate.
        Cookies.remove('_gat', { path: '/', domain: document.domain });
      }

    handleChange(event) {
        if(event.target.value === 'analytics' && this.state.analytics === true) {
            this.setState({analytics:false});
        }
        else if (event.target.value === 'analytics' && this.state.analytics === false) {
            this.setState({analytics:true});
        }

        if(event.target.value === 'marketing' && this.state.marketing === true) {
            this.setState({marketing:false});
        }
        else if (event.target.value === 'marketing' && this.state.marketing === false) {
            this.setState({marketing:true});
        }
    }

    
    handleSubmit(event) {
        Cookies.set('CookieConsent', {analytics:this.state.analytics,marketing:this.state.marketing,functionality:true});
        //console.log("Handling Submit");

        if (this.state.analytics === false) {
            this.disableDefaultTracker();
        }
        window.location.reload(false);
        alert("Muutokset tallennettu");
        event.preventDefault();
    }

    render() {
        return (
            <div id="evasteetMuokkaus" className="text_content">
                <h2>Muuta evästeasetuksia</h2>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        <input id="AnalyticBtn" type="checkbox" checked={this.state.analytics} onChange={this.handleChange} name="cookie1" value="analytics"></input>
                        Analytiikka
                    </label>
                    
                    <br></br>
                    
                    <label>
                        <input id="MarketBtn" type="checkbox" checked={this.state.marketing} onChange={this.handleChange} name="cookie2" value="marketing"></input>
                        Markkinointi
                    </label>
                    
                    <br></br>
                    
                    <label>
                        <input id="FunctBtn" type="checkbox" checked={this.state.functionality} onChange={this.handleChange} name="cookie3" value="embed" disabled></input>
                        Toiminnallisuus
                    </label>

                    <br></br><br></br>
                    <input type="submit" value="Vahvista"></input>
                </form>
            </div>
        )
    }

    componentDidMount() {
        var Cookie_Value = getCookieConsentValue();
        if (Cookie_Value !== undefined) {
            Cookie_Value = JSON.parse(Cookie_Value);
            this.setState({analytics:Cookie_Value.analytics,marketing:Cookie_Value.marketing})
        }
        //console.log(Cookie_Value.analytics);
    }
}

export default EvasteetMuok