import React from 'react';
import './footer.scss';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="grid-container">
                <div className="Logo"><img alt="Joensuun Näköpiste" id="footer_logo" src='https://nakopiste.fi/Resources/logot/nakopiste_logo.png'></img></div>
                <div className="Address">
                    <p>
                        Siltakatu 12,<br></br> Joensuu, 80100<br></br> 050 3585855
                    </p>
                </div>
                <div className="Times">
                    <p>
                        Palvelemme:<br></br>
                        MA-PE 10:00-17:30<br></br>
                        LA 10-14:00<br></br>
                    </p>
                </div>
            </div>
        )
    }
}

export default Footer