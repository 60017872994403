import React from 'react';
import MetaTags from 'react-meta-tags';
import ReactMarkdown from 'react-markdown';
import AppMarkdown from './huomiot.md';
const gfm = require('remark-gfm')

class Huomiot extends React.Component {
    constructor(props) {
        super(props);
        this.state = { markdown: null };
      }
    
      componentDidMount() {
        document.title = "Joensuun NäköPiste tiedottaa"
        fetch(AppMarkdown).then((response) => response.text()).then((text) => { this.setState({ markdown: text }); })
      }
    
      render() {
        const { markdown } = this.state;
        if(this.state.markdown !== null) {
            return (
            <div className="main-content">
                <MetaTags>
                    <meta name="description" content="Joensuun NäköPiste tiedottaa ajankohtaisista asioista" />
                </MetaTags>
                <div className="text_content">
                    <ReactMarkdown remarkPlugins={[gfm]} children={markdown} />
                </div>
                <br></br>
            </div>
            );
        }
        else {
            return (
                <div className="Ajankohtaista-content">
                    <div>Ladataan...</div>
                </div>
            )
        }
      }
}

export default Huomiot